export default (term) => {
  switch (term) {
    case "day":
      return "jour"
    case "halfDay":
      return "demi-journée"
    case "week":
      return "semaine"
  }
}
